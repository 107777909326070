
import { defineComponent, ref } from 'vue'
import PageHeader from '@/components/PageHeader.vue'
import { useRoute } from 'vue-router'
import Spinner from '@/components/Spinner.vue'
import useAmend from '@/composables/useAmend'
import Snackbar from '@/components/Snackbar.vue'
import Checkbox from '@/components/Checkbox.vue'

export default defineComponent({
  name: 'AmendBookingSettings',
  components: {
    PageHeader,
    Spinner,
    Snackbar,
    Checkbox
  },
  setup () {
    const route = useRoute()
    const bookingInfo = ref()
    const loadingAmend = ref(true)
    const amendState = useAmend()
    const amendmentId = route.params.amendId as string
    const bookingId = route.params.bookingId as string
    const editDates = ref(false)
    const editOutbound = ref(false)
    const editReturn = ref(false)
    amendState.verifyAmend(amendmentId, bookingId).finally(() => {
      loadingAmend.value = false
    })

    const continueAmend = () => {
      amendState.amendment.value.editOutbound = editOutbound.value
      amendState.amendment.value.editReturn = editReturn.value
      amendState.amendment.value.editDates = editDates.value
      amendState.goToNextStep('AmendBookingSettings')
    }
    return {
      editDates,
      editOutbound,
      editReturn,
      continueAmend,
      amendment: amendState.amendment,
      bookingInfo,
      loadingAmend
    }
  }
})
