
import { defineComponent, ref, PropType, computed } from 'vue'
import { ServerAmend } from '@/models/Amends'
import { TabOption } from '@/models/System'
import { formatPrice } from '@/helpers'
import Tabs from '@/components/Tabs.vue'
import PageHeader from '@/components/PageHeader.vue'
import AmendItinerary from '@/components/BookingAmends/AmendItinerary.vue'

export default defineComponent({
  name: 'AmendBookingResume',
  emits: ['updatedJourney'],
  components: {
    Tabs,
    PageHeader,
    AmendItinerary
  },
  props: {
    serverAmend: {
      type: Object as PropType<ServerAmend>,
      required: true
    }
  },
  setup (props) {
    const tabSelected = ref('')
    const tabsOptions = ref([] as TabOption[])
    const updateTabSelected = (newTab: string) => {
      tabSelected.value = newTab
    }

    const outboundEdited = ref()
    const returnEdited = ref()

    outboundEdited.value = props.serverAmend.Ways.find(x => x.IsOutbound)
    if (outboundEdited.value) tabsOptions.value.push({ value: 'outbound', label: 'Outward' })

    returnEdited.value = props.serverAmend.Ways.find(x => !x.IsOutbound)
    if (returnEdited.value) tabsOptions.value.push({ value: 'return', label: 'Return' })

    tabSelected.value = tabsOptions.value[0].value

    const journeyTabFiltered = computed(() => tabSelected.value === 'outbound'
      ? outboundEdited.value
      : returnEdited.value)

    const priceVariation = computed(() =>
      props.serverAmend.Ways.reduce(
        (a, b) =>
          a + b.ServiceModificationCost + b.JourneyModificationCost,
        0))
    const modificationFees = computed(() =>
      props.serverAmend.ModificationFees.reduce(
        (a, b) => a + b.Amount,
        0))

    return {
      modificationFees,
      priceVariation,
      journeyTabFiltered,
      updateTabSelected,
      tabSelected,
      tabsOptions,
      formatPrice
    }
  }
})
