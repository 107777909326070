
import GenericError from '@/components/GenericError.vue'
import { computed, defineComponent, provide, ref } from 'vue'
import PageHeader from '@/components/PageHeader.vue'
import { useRoute } from 'vue-router'
import AmendBookingResumeComponent from '@/components/BookingAmends/AmendBookingResume.vue'
import Spinner from '@/components/Spinner.vue'
import useAmend from '@/composables/useAmend'
import router from '@/router'
import BasketFooter from '@/components/BasketFooter.vue'

export default defineComponent({
  name: 'AmendBookingResumeView',
  components: {
    GenericError,
    PageHeader,
    Spinner,
    AmendBookingResumeComponent,
    BasketFooter
  },
  setup () {
    const route = useRoute()
    const loadingAmend = ref(true)
    const amendmentId = route.params.amendId as string
    const bookingId = route.params.bookingId as string
    const amendState = useAmend()
    const serverAmend = ref()
    const error = ref('')
    amendState.loadExistingAmend(amendmentId)
      .then(amend => {
        serverAmend.value = amend
      })
      .catch(e => {
        error.value = e.message
      })
      .finally(() => {
        loadingAmend.value = false
      })

    const tryAgain = () => {
      router.push(({ name: 'AmendBooking', params: { bookingId: bookingId } }))
    }

    provide('amendPriceVariation', computed(() =>
      serverAmend.value?.Ways.reduce(
        (a, b) =>
          a + b.ServiceModificationCost + b.JourneyModificationCost,
        0) ?? null))
    provide('totalPrice', computed(() => serverAmend.value?.ModificationCost ?? 0))
    provide('passengerCount', computed(() => serverAmend.value?.Passengers?.length ?? 0))

    return {
      error,
      tryAgain,
      passengers: amendState.passengersPriceGroups,
      amendment: amendState.amendment,
      goToNextStep: amendState.goToNextStep,
      loadingAmend,
      serverAmend
    }
  }
})
