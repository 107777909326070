
import { defineComponent, watch, PropType } from 'vue'
import useTabs from '@/composables/useTabs'
import { TabOption } from '@/models/System'

export default defineComponent({
  emits: ['changedTab'],
  props: {
    options: {
      type: Array as PropType<TabOption[]>,
      required: true
    }
  },
  setup (props, { emit }) {
    const tabOptions = props.options.map(o => o.value)
    const { activeTab, nextTab, previousTab } = useTabs(tabOptions)
    watch(activeTab, () => {
      emit('changedTab', activeTab.value)
    })
    return {
      activeTab,
      nextTab,
      previousTab
    }
  }
})
