
import { defineComponent, ref, computed, provide } from 'vue'
import { useRoute } from 'vue-router'
import AmendBookingJourneySelector from '@/components/BookingAmends/AmendBookingJourneySelector.vue'
import Spinner from '@/components/Spinner.vue'
import { AmendJourney } from '@/models/Amends'
import { PriceClass } from '@/models/Journey'
import { TravelAccountJourneyDetailed } from '@/models/TravelAccountJourney'
import useAmend from '@/composables/useAmend'
import PageHeader from '@/components/PageHeader.vue'

export default defineComponent({
  name: 'AmendBookingJourney',
  components: {
    Spinner,
    AmendBookingJourneySelector,
    PageHeader
  },
  props: {
    section: String
  },
  setup (props) {
    const route = useRoute()
    const loadingAmend = ref(true)
    const amendState = useAmend()
    const amendmentId = route.params.amendId as string
    const bookingId = route.params.bookingId as string
    amendState.verifyAmend(amendmentId, bookingId).finally(() => {
      loadingAmend.value = false
    })
    const currentReturn = computed(() => {
      if (!amendState.amendment.value.BookingInfo) return {} as TravelAccountJourneyDetailed

      return amendState.amendment.value.BookingInfo.find(
        (journey: TravelAccountJourneyDetailed) =>
          journey.IsOutbound === false) as TravelAccountJourneyDetailed
    })

    const updatedReturn = (selectedPriceClass: PriceClass, journey: AmendJourney) => {
      amendState.amendment.value.NewReturnJourney = journey
      amendState.amendment.value.Prices.Return = selectedPriceClass.Price
      amendState.goToNextStep('AmendBookingReturn')
    }

    const currentOutbound = computed(() => {
      if (!amendState.amendment.value.BookingInfo) return {} as TravelAccountJourneyDetailed

      return amendState.amendment.value.BookingInfo.find(
        (journey: TravelAccountJourneyDetailed) =>
          journey.IsOutbound === true) as TravelAccountJourneyDetailed
    })

    const updatedOutbound = (selectedPriceClass: PriceClass, journey: AmendJourney) => {
      amendState.amendment.value.NewOutboundJourney = journey
      amendState.amendment.value.Prices.Outbound = selectedPriceClass.Price
      amendState.goToNextStep('AmendBookingOutbound')
    }
    const title = computed(() => {
      const journeyType = props.section === 'outbound' ? 'Outward' : 'Return'
      return `Select new ${journeyType}`
    })

    const updateOutboundIndex = (index: number) => {
      amendState.amendment.value.OutboundSelectedIndex = index
    }
    const updateReturnIndex = (index: number) => {
      amendState.amendment.value.ReturnSelectedIndex = index
    }

    const priceVariation = computed(() => {
      const outboundVariation =
        amendState.amendment.value.OutboundSelectedPrice.Price || 0
      const returnVariation =
        amendState.amendment.value.ReturnSelectedPrice.Price || 0
      return outboundVariation + returnVariation
    })

    provide('amendPriceVariation', priceVariation)

    return {
      updateOutboundIndex,
      updateReturnIndex,
      title,
      currentOutbound,
      updatedOutbound,
      pricePassengers: amendState.passengersPriceGroups,
      updatedReturn,
      amendment: amendState.amendment,
      loadingAmend,
      currentReturn
    }
  }
})
