
import { defineComponent, PropType } from 'vue'
import { ServerAmendWay } from '@/models/Amends'
import { formatDate, formatTime } from '@/helpers'

export default defineComponent({
  props: {
    journey: {
      type: Object as PropType<ServerAmendWay>,
      required: true
    },
    direction: {
      type: String,
      required: true
    }
  },
  setup () {
    return { formatDate, formatTime }
  }
})
