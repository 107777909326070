import { Amend } from '@/models/Amends'

// eslint-disable-next-line complexity
const nextValidStep = (currentStep: string, currentAmend: Amend) => {
  const amendSteps = [
    'AmendBooking',
    'AmendBookingSettings',
    currentAmend.editDates && 'AmendBookingDates',
    currentAmend.editOutbound && 'AmendBookingOutbound',
    currentAmend.editReturn && 'AmendBookingReturn',
    'AmendBookingResume',
    'AmendBookingPayment'
  ].filter(step => step)

  const currentIndex = amendSteps.indexOf(currentStep)

  return amendSteps[currentIndex + 1] || 'error'
}
export { nextValidStep }
