
import { defineComponent, ref, computed } from 'vue'
import { getBookingInfo } from '@/expressway-api/travelAccountJourneys.api'
import { useRoute } from 'vue-router'
import GenericError from '@/components/GenericError.vue'
import { Amend, ServerAmendPassenger } from '@/models/Amends'
import { TravelAccountJourneyDetailed } from '@/models/TravelAccountJourney'
import { AxiosError } from 'axios'
import { fareStrings } from '@/models/FareClass'
import { initBookingAmend, cancelCurrentAmend } from '@/expressway-api/bookingActions.api'
import PageHeader from '@/components/PageHeader.vue'
import Spinner from '@/components/Spinner.vue'
import AlertIcon from '@/components/vectors/AlertIcon.vue'
import CheckboxList from '@/components/CheckboxList.vue'
import Checkbox from '@/components/Checkbox.vue'
import useSnackbar from '@/composables/useSnackbar'
import useAmend from '@/composables/useAmend'
import Snackbar from '@/components/Snackbar.vue'

export default defineComponent({
  name: 'AmendBooking',
  components: {
    PageHeader,
    Spinner,
    AlertIcon,
    CheckboxList,
    GenericError,
    Checkbox,
    Snackbar
  },
  // eslint-disable-next-line max-lines-per-function
  setup () {
    const { setSnackbar } = useSnackbar()
    const route = useRoute()
    const loading = ref(true)
    const bookingId = route.params.bookingId as string
    const amendState = useAmend()
    amendState.clearAmend()
    const bookingInfo = ref([] as TravelAccountJourneyDetailed[])
    const loadBookingInfo = async () => {
      bookingInfo.value = await getBookingInfo(bookingId).catch(() => [])
      if (
        bookingInfo.value &&
        bookingInfo.value.length > 0 &&
        bookingInfo.value[0].ActiveModificationSessionId
      ) {
        await cancelCurrentAmend(bookingInfo.value[0].ActiveModificationSessionId)
      }
      loading.value = false
    }
    const passengersSelected = ref([] as string[])

    loadBookingInfo()
    const currentOutbound = computed(() =>
      bookingInfo.value.find(
        (x: TravelAccountJourneyDetailed) =>
          x.IsOutbound === true) as TravelAccountJourneyDetailed
    )

    const currentReturn = computed(() =>
      bookingInfo.value.find(
        (x: TravelAccountJourneyDetailed) =>
          x.IsOutbound === false) as TravelAccountJourneyDetailed
    )

    const uniquePassengers = computed(() =>
      bookingInfo.value.reduce((
        addedPassengers: ServerAmendPassenger[],
        journey: TravelAccountJourneyDetailed
      ) => {
        if (journey.IsOutbound) {
          journey.Passengers.forEach(p => {
            if (!addedPassengers.some((addedPassenger: ServerAmendPassenger) =>
              addedPassenger.ProductCode === p.ProductCode
            )) {
              const PassengerTypeDisplay = fareStrings[p.PassengerCategory]
              addedPassengers.push({ ...p, PassengerTypeDisplay })
            }
          })
        }
        return addedPassengers
      }, [] as ServerAmendPassenger[]))

    const initAmend = () => {
      const passengers =
        uniquePassengers.value.filter(
          (p: ServerAmendPassenger) => passengersSelected.value.includes(p.ProductCode)
        ).map((p: ServerAmendPassenger) => ({
          ProductCode: p.ProductCode,
          PassengerName: p.PassengerName,
          PassengerCategory: p.PassengerCategory,
          PassengerTypeDisplay: p.PassengerTypeDisplay
        } as ServerAmendPassenger))
      const shoppingBasketCode = bookingInfo.value[0].ShoppingBasketCode ?? 0
      loading.value = true
      initBookingAmend(
        shoppingBasketCode,
        passengersSelected.value
      ).then(response => {
        amendState.clearAmend()
        amendState.amendment.value = {
          ...response,
          Passengers: passengers,
          BookingId: bookingId,
          Prices: {
            Outbound: 0,
            Return: 0,
            ModificationFee: response.Fee
          },
          BookingInfo: bookingInfo.value,
          OutboundSelectedIndex: 0,
          ReturnSelectedIndex: 0,
          OutboundSelectedPrice: {},
          ReturnSelectedPrice: {}
        } as Amend
        amendState.amendment.value.NewOutboundDate =
          currentOutbound.value?.DepartureTime ?? ''
        amendState.amendment.value.NewReturnDate = currentReturn.value?.DepartureTime ?? ''
        amendState.goToNextStep('AmendBooking')
      })
        .catch((e: AxiosError) => { setSnackbar(e.message) })
        .finally(() => { loading.value = false })
    }

    const allSelected = computed(() =>
      passengersSelected.value.length === uniquePassengers.value.length
    )

    const selectAll = () => {
      if (allSelected.value) {
        passengersSelected.value = []
      } else {
        passengersSelected.value = uniquePassengers.value.map(p => (p.ProductCode))
      }
    }

    return {
      allSelected,
      selectAll,
      uniquePassengers,
      amendment: amendState.amendment,
      initAmend,
      loading,
      fareStrings,
      passengersSelected,
      bookingInfo
    }
  }
})
