
import { defineComponent, ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { TravelAccountJourneyDetailed } from '@/models/TravelAccountJourney'
import PageHeader from '@/components/PageHeader.vue'
import Spinner from '@/components/Spinner.vue'
import useAmend from '@/composables/useAmend'
import dayjs from 'dayjs'
import DatePicker from '@/components/DatePicker/DatePicker.vue'

export default defineComponent({
  name: 'AmendBookingDates',
  components: {
    PageHeader,
    Spinner,
    DatePicker
  },
  setup () {
    const today = dayjs()
    const todayString = today.format('YYYY-MM-DD')
    const newOutboundDate = ref('')
    const newReturnDate = ref('')
    const route = useRoute()
    const loadingAmend = ref(true)
    const amendState = useAmend()
    const maxDate = dayjs().add(180, 'day').format('YYYY-MM-DD')
    const amendmentId = route.params.amendId as string
    const bookingId = route.params.bookingId as string
    const activeType = ref(amendState.amendment.value.editOutbound ? 'Outbound' : 'Return')
    amendState.verifyAmend(amendmentId, bookingId).finally(() => {
      loadingAmend.value = false
    })
    newOutboundDate.value = amendState.amendment.value.NewOutboundDate ?? ''

    const currentOutbound = computed(() => {
      if (!amendState.amendment.value.BookingInfo) return {} as TravelAccountJourneyDetailed

      return amendState.amendment.value.BookingInfo.find(
        (journey: TravelAccountJourneyDetailed) =>
          journey.IsOutbound === true) as TravelAccountJourneyDetailed
    })

    const currentReturn = computed(() => {
      if (!amendState.amendment.value.BookingInfo) return {} as TravelAccountJourneyDetailed

      return amendState.amendment.value.BookingInfo.find(
        (journey: TravelAccountJourneyDetailed) =>
          journey.IsOutbound === false) as TravelAccountJourneyDetailed
    })

    const minReturnDate = computed(() => {
      if (newOutboundDate.value !== '') {
        if (dayjs(newOutboundDate.value).isBefore(today, 'day')) {
          return todayString
        } else {
          return newOutboundDate.value
        }
      }

      if (dayjs(currentOutbound.value.DepartureTime).isBefore(today, 'day')) return todayString

      return currentOutbound.value.DepartureTime
    })

    const maxSelectDay = computed(() => {
      if (currentReturn.value && !amendState.amendment.value.editReturn) {
        return currentReturn.value.DepartureTime
      } else {
        return maxDate
      }
    })
    const confirmDate = () => {
      if (activeType.value === 'Outbound') {
        amendState.amendment.value.NewOutboundDate = newOutboundDate.value
      } else {
        amendState.amendment.value.NewReturnDate = newReturnDate.value
      }
      if (activeType.value === 'Outbound' && amendState.amendment.value.editReturn) {
        activeType.value = 'Return'
      } else {
        amendState.goToNextStep('AmendBookingDates')
      }
    }
    return {
      maxSelectDay,
      todayString,
      minReturnDate,
      activeType,
      newOutboundDate,
      newReturnDate,
      confirmDate,
      loadingAmend,
      amendState: amendState.amendment.value,
      currentOutbound,
      currentReturn
    }
  }
})
