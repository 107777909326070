import { getPaymentMethods } from '@/expressway-api/wallet'
import useVuelidate from '@vuelidate/core'
import { helpers, maxLength, maxValue, minLength, minValue, required } from '@vuelidate/validators'
import dayjs from 'dayjs'
import { reactive, watch, computed, ref, toRef } from 'vue'
import useSessions from './useSessions'

interface CardState {
  cardHolderName: string;
  cardNumber: string;
  expiryMonth: string;
  expiryYear: string;
  cvv: string;
  saveCard: boolean;
  useSavedCard?: boolean;
  loading?: boolean;
}

const rules = {
  cardHolderName: {
    required,
    minLength: minLength(2),
    maxLength: maxLength(40)
  },
  cardNumber: {
    required,
    // Actual validations are length from 13-19 but these values need
    // to be set to work around the input masking.
    minLength: helpers.withMessage('This field should be at least 13 long', minLength(15)),
    maxLength: maxLength(23)
  },
  expiryYear: {
    required,
    minValue: minValue(dayjs().year()),
    maxValue: maxValue(dayjs().year() + 15)
  },
  expiryMonth: {
    required,
    minValue: minValue(1),
    maxValue: maxValue(12)
  },
  cvv: {
    required,
    minLength: minLength(3),
    maxLength: maxLength(4)
  }
}

const stateToDDCRequestBody = (state: CardState) => state.useSavedCard
  ? { PayWithExistingCard: true }
  : {
      CardHolderName: state.cardHolderName,
      CardNumber: state.cardNumber.replace(/[^0-9]/gi, ''),
      ExpiryMonth: Number(state.expiryMonth),
      ExpiryYear: Number(state.expiryYear),
      SaveCardToAccount: state.saveCard
    }

const formatCardNumber = (cardNumber: string) =>
  cardNumber
    .replace(/[^0-9*]/gi, '')
    .replace(/[\d*]{4}(?=.)/g, '$& ')

export default function usePaymentMethod () {
  const state = reactive<CardState>({
    cardHolderName: '',
    cardNumber: '',
    expiryMonth: '',
    expiryYear: '',
    cvv: '',
    saveCard: false,
    useSavedCard: false,
    loading: false
  })

  const savedCard = ref()
  const validator = useVuelidate(rules, state)
  const { isLoggedIn } = useSessions()
  const requestBody = computed(() => stateToDDCRequestBody(state))

  watch(() => state.cardNumber, () => {
    state.cardNumber = formatCardNumber(state.cardNumber)
  })

  if (isLoggedIn.value) {
    state.loading = true
    getPaymentMethods()
      .then(({ data: { PaymentMethod: paymentMethod } }) => {
        savedCard.value = {
          cardHolderName: paymentMethod.CardHolderName,
          cardNumber: paymentMethod.CardNumber,
          expiryYear: paymentMethod.CardExpiryDate.Year,
          expiryMonth: paymentMethod.CardExpiryDate.Month
        }
        state.useSavedCard = true
      })
      .catch(() => { state.useSavedCard = false })
      .finally(() => { state.loading = false })
  }

  watch(() => state.useSavedCard, useSavedCard => {
    if (useSavedCard) {
      state.cardHolderName = savedCard.value.cardHolderName
      state.cardNumber = savedCard.value.cardNumber
      state.expiryMonth = savedCard.value.expiryMonth
      state.expiryYear = savedCard.value.expiryYear
    } else {
      state.cardHolderName = ''
      state.cardNumber = ''
      state.expiryMonth = ''
      state.expiryYear = ''
      validator.value.$reset
    }
  })

  return {
    validator,
    saveCard: toRef(state, 'saveCard'),
    useSavedCard: toRef(state, 'useSavedCard'),
    loadingCard: toRef(state, 'loading'),
    savedCard,
    requestBody,
    cvv: toRef(state, 'cvv'),
    clearCvv: () => { state.cvv = '' }
  }
}
