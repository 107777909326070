import { Amend, ServerAmend } from '@/models/Amends'
import { computed, readonly, provide, toRefs } from 'vue'
import useLocalStorage from './useLocalStorage'
import { loadAmend } from '@/expressway-api/bookingActions.api'
import { nextValidStep } from '@/helpers/amendHelper'
import { getBookingInfo } from '@/expressway-api/travelAccountJourneys.api'
import { PassengerGroupType, PassengerGroup } from '@/models/Passenger'

import router from '@/router'

const amendProcessState = useLocalStorage(
  'amendProcessState', {
    amendment: {} as Amend
  }
)

// eslint-disable-next-line max-lines-per-function
export default function useAmend () {
  const clearAmend = () => {
    amendProcessState.amendment = {} as Amend
  }
  const loadExistingAmend = (amendmentId: string) => loadAmend(amendmentId)
    .then(async (amend: ServerAmend) => {
      amendProcessState.amendment.Passengers = amend.Passengers
      amendProcessState.amendment.ProcessId = amendmentId
      amendProcessState.amendment.Prices = {
        ModificationFee: amend.ModificationFees.reduce((a, b) => a + b.Amount, 0),
        Outbound: amend.Ways.reduce(
          (a, b) =>
            a + (b.IsOutbound ? (b.JourneyModificationCost + b.ServiceModificationCost) : 0),
          0
        ),
        Return: amend.Ways.reduce(
          (a, b) =>
            a + (!b.IsOutbound ? (b.JourneyModificationCost + b.ServiceModificationCost) : 0),
          0
        )
      }
      amendProcessState.amendment.BookingId = amend.ShoppingBasketId
      amendProcessState.amendment.BookingInfo =
        await getBookingInfo(amendProcessState.amendment.BookingId)
      return amend
    })
    .catch(e => {
      clearAmend()
      throw new Error(e)
    })
  const goToNextStep = (currentStep: string) => {
    const NextLocation = nextValidStep(currentStep, amendProcessState.amendment)
    router.push(
      {
        name: NextLocation,
        params: {
          bookingId: amendProcessState.amendment.BookingId,
          amendId: amendProcessState.amendment.ProcessId
        }
      })
  }
  const verifyAmend = async (amendmentId: string, bookingId: string) => {
    if (
      amendmentId !== amendProcessState.amendment.ProcessId ||
      bookingId !== amendProcessState.amendment.BookingId
    ) {
      // if we want to load the amend from server this is not going to work because some selections
      // will be missing. For now it will go to the initial amend screen for the booking Id provided
      router.push({ name: 'AmendBooking', params: { bookingId } })
    }
  }
  const passengersPriceGroups = computed(() => {
    const group: PassengerGroupType[] = []
    if (!amendProcessState.amendment?.Passengers) return group

    amendProcessState.amendment.Passengers.forEach(passenger => {
      const index = group.findIndex(p =>
        p.passengerType === passenger.PassengerCategory
      )

      if (index >= 0) {
        group[index].quantity++
      } else {
        group.push({
          price: 0,
          passengerType: passenger.PassengerCategory,
          passengerTypeDisplay: passenger.PassengerCategory,
          quantity: 1
        })
      }
    })
    const types: PassengerGroup[] = []
    if (amendProcessState.amendment.editOutbound) {
      types.push({
        groupType: 'Outward',
        passengers: group
      } as PassengerGroup)
    }
    if (amendProcessState.amendment.editReturn) {
      types.push({
        groupType: 'Return',
        passengers: group
      } as PassengerGroup)
    }
    return types as PassengerGroup[]
  })

  const passengerCount = computed(() => passengersPriceGroups.value.length)

  provide('promoCode', '')
  provide('pricingPassengers', readonly(passengersPriceGroups))
  provide('passengerCount', readonly(passengerCount))
  provide('amendFee', computed(() => amendProcessState.amendment.Prices.ModificationFee))

  return {
    passengersPriceGroups,
    verifyAmend,
    clearAmend,
    ...toRefs(amendProcessState),
    loadExistingAmend,
    goToNextStep
  }
}
