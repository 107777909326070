
import {
  computed,
  defineComponent,
  provide,
  ref
} from 'vue'
import BasketFooter from '@/components/BasketFooter.vue'
import GenericError from '@/components/GenericError.vue'
import Modal from '@/components/Modal.vue'
import PageHeader from '@/components/PageHeader.vue'
import PaymentDetails from '@/components/PaymentDetails.vue'
import PaymentForm from '@/components/PaymentForm.vue'
import Spinner from '@/components/Spinner.vue'
import useAmend from '@/composables/useAmend'
import usePaymentMethod from '@/composables/usePaymentMethod'
import useSnackbar from '@/composables/useSnackbar'
import { ServerAmend } from '@/models/Amends'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: {
    BasketFooter,
    GenericError,
    Modal,
    PageHeader,
    PaymentDetails,
    PaymentForm,
    Spinner
  },
  // eslint-disable-next-line max-lines-per-function
  setup () {
    const amendState = useAmend()
    const route = useRoute()
    const amendmentId = route.params.amendId as string
    const challengeIframeSrc = ref('')
    const ddcIframeSrc = ref('')
    const loading = ref(false)
    const loadingAmend = ref(true)
    const paymentDetailRef = ref<InstanceType<typeof PaymentDetails>>()
    const paymentNeeded = ref(false)
    const sendPayment = () => { paymentDetailRef.value?.sendPayment('AmendBookingSuccess') }
    const serverAmend = ref<ServerAmend>()
    const showErrorModal = ref(false)
    const { setSnackbar } = useSnackbar()

    const {
      validator,
      saveCard,
      useSavedCard,
      savedCard,
      loadingCard,
      requestBody
    } = usePaymentMethod()

    paymentNeeded.value = true

    amendState.loadExistingAmend(amendmentId)
      .then(amend => {
        serverAmend.value = amend
      })
      .catch(e => {
        setSnackbar(e.message)
      })
      .finally(() => {
        loadingAmend.value = false
      })

    const resetForm = async () => {
      loading.value = false
    }

    provide('totalPrice', computed(() => serverAmend.value?.ModificationCost ?? 0))
    provide('passengerCount', computed(() => serverAmend.value?.Passengers.length ?? 0))

    return {
      amendment: amendState.amendment,
      amendmentId,
      challengeIframeSrc,
      ddcIframeSrc,
      loading,
      loadingAmend,
      loadingCard,
      passengers: amendState.passengersPriceGroups,
      paymentDetailRef,
      requestBody,
      resetForm,
      saveCard,
      savedCard,
      sendPayment,
      serverAmend,
      showErrorModal,
      useSavedCard,
      validator
    }
  }
})
