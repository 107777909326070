import { httpClient } from './httpClient'
import {
  TravelAccountJourney,
  TravelAccountJourneySet,
  TravelAccountJourneyDetailed
} from '@/models/TravelAccountJourney'

const travelAccountJourneysRoute = '/travel-accounts/journeys'
const bookingInfoRoute = '/baskets/:id/booked-journey-data'
const getTicketsByEmailRoute = '/tickets/by-email'
const basketTicketsRoute = '/baskets/:basket_id/tickets'

const addUnmodifiedJourney = (
  bundledJourneys: TravelAccountJourneySet[],
  a: TravelAccountJourney,
  b?: TravelAccountJourney
) => bundledJourneys.push({
  ShoppingBasketId: a.ShoppingBasketId,
  [a?.IsOutbound ? 'Outbound' : 'Inbound']: a,
  [b?.IsOutbound ? 'Outbound' : 'Inbound']: b
})

const addModifiedJourneys = (
  bundledJourneys: TravelAccountJourneySet[],
  journeys: TravelAccountJourney[]
) => journeys.forEach(j => bundledJourneys.push({
  ShoppingBasketId: j.ShoppingBasketId,
  [j.IsOutbound ? 'Outbound' : 'Inbound']: j
}))

/*
If a booking with multiple tickets is partially modified then it can split into multiple
outward and inward journeys per shopping basket id. In this case it is not possible to
associate the correct outward journeys with their inward journeys. This function bundles
outward and inward journeys together but splits them apart in the case described above.

It does this by grouping journeys by shopping basket id, then checking if there are more
than two journeys associated with it (hence modified and unbundlable) or if the
"is outbound" property matches on the first two journeys (hence modified and unbundlable)
*/
const bundleTickets = (journeys: TravelAccountJourney[]) =>
  Object.values(journeys
    .reduce((map, j) => {
      (map[j.ShoppingBasketId] = map[j.ShoppingBasketId] || []).push(j)
      return map
    }, {} as { [x: string]: TravelAccountJourney[] })
  ).reduce((bundledJourneys, journeys) => {
    const [a, b, ...rest] = journeys
    if (a.IsOutbound === b?.IsOutbound || rest.length) {
      addModifiedJourneys(bundledJourneys, journeys)
    } else {
      addUnmodifiedJourney(bundledJourneys, a, b)
    }
    return bundledJourneys
  }, [] as TravelAccountJourneySet[])

const getTravelAccountJourneys = (): Promise<TravelAccountJourneySet[]> =>
  httpClient
    .get<{ Journeys: TravelAccountJourney[] }>(travelAccountJourneysRoute)
    .then(response => bundleTickets(response.data.Journeys))

const getBookingInfo = (basketId: string): Promise<TravelAccountJourneyDetailed[]> => {
  const route = bookingInfoRoute.replace(':id', basketId)
  return httpClient
    .get(route)
    .then(response => response.data.Journey.JourneyWays)
}
const getTicketsByEmail =
  async (email: string, ticketId: string): Promise<string|false> =>
    await httpClient.post(getTicketsByEmailRoute,
      { email: email, productCode: ticketId })
      .then(response => response.data.Guid || false)
      .catch(() => false)

const getBasketTickets = (basketId: string) => {
  const route = basketTicketsRoute.replace(':basket_id', basketId)
  return httpClient
    .get<{ Journey: TravelAccountJourney[] }>(route)
    .then(response => response.data.Journey)
}

export {
  getTravelAccountJourneys,
  getBookingInfo,
  getBasketTickets,
  getTicketsByEmail
}
