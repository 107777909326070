
import { defineComponent, ref, PropType, computed, watch, provide } from 'vue'
import Spinner from '@/components/Spinner.vue'
import { loadAmendJourneys, addJourneyToAmend } from '@/expressway-api/bookingActions.api'
import { AmendOptions, PriceAmend, PriceInfoAmend, ServerAmendPassenger } from '@/models/Amends'
import JourneyFeed from '@/components/JourneyFeed.vue'
import BasketFooter from '@/components/BasketFooter.vue'
import { PriceInfo } from '@/models/Journey'
import useSnackbar from '@/composables/useSnackbar'
import GenericError from '../GenericError.vue'

export default defineComponent({
  name: 'AmendBookingJourneySelector',
  emits: ['updatedJourney', 'update:selectedPrice', 'update:selectedIndex'],
  components: {
    Spinner,
    JourneyFeed,
    BasketFooter,
    GenericError
  },
  props: {
    isOutbound: {
      type: Boolean,
      required: true
    },
    amendProcessId: {
      type: String,
      required: true
    },
    destinationBusStopId: {
      type: Number,
      required: true
    },
    originBusStopId: {
      type: Number,
      required: true
    },
    departureDate: {
      type: String,
      required: true
    },
    passengers: {
      type: Array as PropType<ServerAmendPassenger[]>,
      required: true
    },
    basket: {
      type: Object as PropType<PriceAmend>,
      required: true
    },
    selectedIndex: {
      type: Number,
      required: true
    },
    selectedPrice: {
      type: Object as PropType<PriceInfoAmend>,
      required: true
    }
  },
  // eslint-disable-next-line max-lines-per-function
  setup (props, { emit }) {
    const { setSnackbar } = useSnackbar()
    const loading = ref(true)
    const loadingAddJourney = ref(false)
    const type = props.isOutbound ? 'Outbound' : 'Return'
    const journeys = ref()
    const error = ref()
    const activeIndex = ref(props.selectedIndex)
    const selectedPriceInternal = ref<PriceInfoAmend>(props.selectedPrice)
    const selectedJourney = computed(() => journeys.value?.[activeIndex.value])
    loadAmendJourneys(
      props.isOutbound,
      props.amendProcessId,
      props.destinationBusStopId,
      props.originBusStopId,
      props.departureDate
    ).then((res: AmendOptions) => {
      journeys.value = res.Journeys.filter(
        j => (j.IsForSale && j.PriceClasses.length > 0) || j.IsModifiableJourney
      )
    })
      .catch(e => {
        error.value = e.message || "We haven't found any trip for the current date"
      })
      .finally(() => {
        loading.value = false
      })
    const totalPrice = computed(() => {
      const total = Object.keys(props.basket).reduce((a, b) => {
        if (type === b) return a

        return a + (props.basket?.[b] ?? 0)
      }, 0)
      return total + (selectedPriceInternal.value?.Price ?? 0)
    })
    const addJourney = () => {
      if (!selectedPriceInternal.value) return

      loading.value = true
      loadingAddJourney.value = true
      addJourneyToAmend(
        props.amendProcessId,
        journeys.value[activeIndex.value].JourneyId,
        props.isOutbound,
        selectedPriceInternal.value?.PriceClassName
      ).then(() => {
        emit('updatedJourney', selectedPriceInternal.value, journeys.value[activeIndex.value])
      }).catch(e => {
        setSnackbar(e.message)
        loading.value = false
        loadingAddJourney.value = false
      })
    }
    // eslint-disable-next-line complexity
    watch(selectedJourney, journey => {
      if (!journey || journey.PriceClasses.length === 0) return

      let newPriceClass = journey.PriceClasses.find((pc: PriceInfo) =>
        pc.PriceClassName === selectedPriceInternal.value?.PriceClassName)
      newPriceClass = newPriceClass ??
         journey.PriceClasses.reduce((a: PriceInfo, b: PriceInfo) => a.Price < b.Price ? a : b)
      selectedPriceInternal.value = newPriceClass ?? {} as PriceInfo
    })

    watch(selectedPriceInternal, price => {
      emit('update:selectedPrice', price)
    })

    watch(activeIndex, index => {
      emit('update:selectedIndex', index)
    })
    const selectedPriceValid = computed(() => Object.keys(selectedPriceInternal.value).length > 0)

    provide('amendFee', props.basket.ModificationFee)
    provide('totalPrice', totalPrice)
    provide('passengerCount', computed(() => props.passengers.length))

    return {
      selectedPriceValid,
      error,
      loadingAddJourney,
      selectedPriceInternal,
      activeIndex,
      journeys,
      type,
      loading,
      addJourney
    }
  }
})
