
import { defineComponent } from 'vue'
import Checkbox from '@/components/Checkbox.vue'
import Input from '@/components/Input.vue'
import Spinner from '@/components/Spinner.vue'
import MonthYearSelector from '@/components/MonthYearSelector.vue'
import useSessions from '@/composables/useSessions'

export default defineComponent({
  emits: ['update:useSavedCard', 'update:saveCard'],
  components: {
    'v-checkbox': Checkbox,
    'v-input': Input,
    MonthYearSelector,
    Spinner
  },
  props: {
    validator: {
      type: Object,
      required: true
    },
    saveCard: {
      type: Boolean,
      required: true
    },
    useSavedCard: {
      type: Boolean,
      required: true
    },
    savedCard: Object,
    loading: {
      type: Boolean,
      default: true
    }
  },
  setup () {
    const { isLoggedIn } = useSessions()

    return {
      isLoggedIn
    }
  }
})
