
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import PageHeader from '@/components/PageHeader.vue'
import PaymentConfirmation from '@/components/PaymentConfirmation.vue'

export default defineComponent({
  components: {
    PageHeader,
    PaymentConfirmation
  },
  setup () {
    const route = useRoute()
    const { bookingId } = route.params

    return {
      bookingId
    }
  }
})
